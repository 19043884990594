<template>
  <div>
    <IncidentComponent></IncidentComponent>
  </div>
  </template>

<script>

import IncidentComponent from "../components/IncidentComponent.vue"

export default {
 
  data() {
    return {
      
    };
  },
  components: { IncidentComponent }
};
</script>
<style></style>